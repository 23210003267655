@import './scss/modules.scss';

body{
  font-family: 'Raleway', sans-serif;
  background-color: #FFFBE9;
}

#hero{
  text-align: center;
  max-width: 320px;
  margin: 0 auto;
  padding: 100px 0;

  img{
    border: 9px solid #EEDB99;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  p{
    color: #403E1D;
    font-size: rem-calc(18);

    a{
      color: #403E1D;
      font-weight: bold;

      &:hover{
        text-decoration: underline;
      }
    }
  }
}

